import React, {useEffect} from "react";
import Home from './Pages/Home';
import Logo from './OBW_Logo_V2.svg';
import {ObwLogo} from "./Assets/ObwLogo";
import './App.css';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger,
    Image,
    Link,
    Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenu,
    NavbarMenuItem, NavbarMenuToggle, Popover, PopoverContent, PopoverTrigger, Spacer, Tooltip
} from "@nextui-org/react";
import Services from "./Pages/Services";

function App() {
    //-- Variables
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const menuItems = ["Home"] // , "Services", "Events"
    const [curPageIndex, setCurPageIndex] = React.useState(0);

    //-- Methods
    function scrollToPosition(percentage = 0) {
        const top = (document.documentElement.scrollHeight - window.innerHeight) * (percentage / 100);
        window.scrollTo({
            top: top,
            behavior: 'smooth'
        });
    }

    //-- Layout
    return (
        <div className="App">
            <header className="App-header">
                <Navbar onMenuOpenChange={setIsMenuOpen}>
                    <NavbarContent>
                        <NavbarMenuToggle
                            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                            className="sm:hidden"
                        />
                        <NavbarBrand>
                            <ObwLogo/>
                            <Spacer x={'2.5'} />
                            <p className="font-bold text-inherit" style={{fontSize: 30}}>OBW</p>
                        </NavbarBrand>
                    </NavbarContent>

                    <NavbarContent className="hidden sm:flex gap-6" justify="center">
                        <NavbarItem isActive={curPageIndex === 0}>
                            <Tooltip placement="bottom"
                                content={
                                    <Link style={{cursor: 'pointer'}} color={curPageIndex === 0 ? "warning" : "foreground"} onClick={ () => {
                                        if (curPageIndex !== 0) {
                                            setCurPageIndex(0);
                                        }

                                        setTimeout(() => { scrollToPosition(40); }, 10);
                                    }}>About</Link>
                                }
                            >
                                <Link style={{cursor: 'pointer'}} color={curPageIndex === 0 ? "warning" : "foreground"} onClick={ () => {
                                    if (curPageIndex !== 0) {
                                        setCurPageIndex(0);
                                    }

                                    setTimeout(() => { scrollToPosition(0); }, 10);
                                }}>Home</Link>
                            </Tooltip>
                        </NavbarItem>

                        {menuItems.slice(1).map((item, index) => (
                            <NavbarItem key={`${item}-${index}`} isActive={curPageIndex === index + 1}>
                                <Link
                                    style={{cursor: 'pointer'}}
                                    color={curPageIndex === index + 1 ? "warning" : "foreground"}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setCurPageIndex(index + 1);
                                        setTimeout(() => { scrollToPosition(0); }, 10);
                                    }}
                                    href="#"
                                >
                                    {item}
                                </Link>
                            </NavbarItem>
                        ))}
                    </NavbarContent>

                    <NavbarContent justify="end">
                        <NavbarItem>
                            <Button id={"phone"} disableRipple className={"phone-button hidden sm:flex"} radius={"full"} isIconOnly color={"default"} variant="solid" aria-label="Call The Store">
                                <svg className={"phone-icon"} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M795-120q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19 13-32t32-13h140q14 0 24 10t14 25l27 126q2 14-.5 25.5T359-634L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23-15t26-2l119 26q15 4 25 16t10 27v135q0 19-13 32t-32 13Z"/></svg>
                            </Button>
                        </NavbarItem>

                        <NavbarItem>
                            <Popover placement="left" showArrow={true}>
                                <PopoverTrigger>
                                    <Button disableRipple className={"phone-button hidden sm:flex"} radius={"full"} isIconOnly color={"default"} variant="solid" aria-label="Hours of Operation">
                                        <svg className={"phone-icon"} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m627-287 45-45-159-160v-201h-60v225l174 181ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-82 31.5-155t86-127.5Q252-817 325-848.5T480-880q82 0 155 31.5t127.5 86Q817-708 848.5-635T880-480q0 82-31.5 155t-86 127.5Q708-143 635-111.5T480-80Z"/></svg>
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <div className="px-1 py-2">
                                        <div className="text-large font-bold">Hours of Operation</div>
                                        <Divider style={{marginTop: 5, marginBottom: 10}}></Divider>
                                        <div className="text-tiny" style={{color: "#cec6c3"}}>Sun: 10:00 am - 9:00 pm</div>
                                        <div className="text-tiny" style={{color: "#cec6c3"}}>Mon: 9:00 am - 9:00 pm</div>
                                        <div className="text-tiny" style={{color: "#cec6c3"}}>Tue: 9:00 am - 9:00 pm</div>
                                        <div className="text-tiny" style={{color: "#cec6c3"}}>Wed: 9:00 am - 9:00 pm</div>
                                        <div className="text-tiny" style={{color: "#cec6c3"}}>Thu: 9:00 am - 9:00 pm</div>
                                        <div className="text-tiny" style={{color: "#cec6c3"}}>Fri: 9:00 am - 10:00 pm</div>
                                        <div className="text-tiny" style={{color: "#cec6c3"}}>Sat: 9:00 am - 10:00 pm (11:00 pm - Latin Nights)</div>
                                    </div>
                                </PopoverContent>
                            </Popover>
                        </NavbarItem>

                        <NavbarItem>
                            <Button id="pickup-btn" style={{borderWidth: 0, borderRadius: 5}} className="btn-test-color" variant="ghost">
                                <svg className={"truck-icon"} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M224.118-161Q175-161 140.5-195.417 106-229.833 106-279H40v-461q0-24 18-42t42-18h579v167h105l136 181v173h-71q0 49.167-34.382 83.583Q780.235-161 731.118-161 682-161 647.5-195.417 613-229.833 613-279H342q0 49-34.382 83.5-34.383 34.5-83.5 34.5ZM224-221q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17Zm507 0q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17Zm-52-204h186L754-573h-75v148Z"/></svg>
                                CURBSIDE PICKUP
                            </Button>
                        </NavbarItem>
                    </NavbarContent>

                    <NavbarMenu>
                        <NavbarMenuItem>
                            <Dropdown>
                                <DropdownTrigger>
                                    <Link color={curPageIndex === 0 ? "warning" : "foreground"} className="w-full" size="lg">Home</Link>
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Menu options">
                                    <DropdownItem key="home" onClick={() => {
                                        if (curPageIndex !== 0) {
                                            setCurPageIndex(0);
                                        }
                                        setTimeout(() => { scrollToPosition(0); }, 10);
                                    }}>Home</DropdownItem>
                                    <DropdownItem key="about" onClick={() => {
                                        if (curPageIndex !== 0) {
                                            setCurPageIndex(0);
                                        }
                                        setTimeout(() => { scrollToPosition(100); }, 10);
                                    }}>About</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </NavbarMenuItem>
                        {menuItems.slice(1).map((item, index) => (
                            <NavbarMenuItem key={`${item}-${index}`}>
                                <Link
                                    color={curPageIndex === index + 1 ? "warning" : "foreground"}
                                    className="w-full"
                                    size="lg"
                                    href="#"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setCurPageIndex(index + 1);
                                        setTimeout(() => { scrollToPosition(0); }, 10);
                                    }}
                                >
                                    {item}
                                </Link>
                            </NavbarMenuItem>
                        ))}

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '100%', width: "auto", marginTop: 'auto', marginBottom: '5%' }}>
                            <NavbarMenuItem style={{width: '10%', marginTop: 'auto'}}>
                                <Card style={{ display: 'flex', flexDirection: 'column', width: 40, alignItems: 'center', justifyContent: 'center', gap: '5%', alignSelf: 'flex-start', marginTop: 'auto', marginRight: '5%' }}>
                                    <NavbarItem>
                                        <Button id={"phone-menu"} disableRipple className={"phone-button-menu"} radius={"full"} isIconOnly color={"default"} variant="solid" aria-label="Call The Store">
                                            <svg className={"phone-icon"} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M795-120q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19 13-32t32-13h140q14 0 24 10t14 25l27 126q2 14-.5 25.5T359-634L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23-15t26-2l119 26q15 4 25 16t10 27v135q0 19-13 32t-32 13Z"/></svg>
                                        </Button>
                                    </NavbarItem>

                                    <NavbarItem>
                                        <Popover placement="bottom" showArrow={true}>
                                            <PopoverTrigger>
                                                <Button disableRipple className={"phone-button-menu"} radius={"full"} isIconOnly color={"default"} variant="solid" aria-label="Hours of Operation">
                                                    <svg className={"phone-icon"} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m627-287 45-45-159-160v-201h-60v225l174 181ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-82 31.5-155t86-127.5Q252-817 325-848.5T480-880q82 0 155 31.5t127.5 86Q817-708 848.5-635T880-480q0 82-31.5 155t-86 127.5Q708-143 635-111.5T480-80Z"/></svg>
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <div className="px-1 py-2">
                                                    <div className="text-large font-bold">Hours of Operation</div>
                                                    <Divider style={{marginTop: 5, marginBottom: 10}}></Divider>
                                                    <div className="text-tiny" style={{color: "#cec6c3"}}>Sun: 10:00 am - 9:00 pm</div>
                                                    <div className="text-tiny" style={{color: "#cec6c3"}}>Mon: 9:00 am - 9:00 pm</div>
                                                    <div className="text-tiny" style={{color: "#cec6c3"}}>Tue: 9:00 am - 9:00 pm</div>
                                                    <div className="text-tiny" style={{color: "#cec6c3"}}>Wed: 9:00 am - 9:00 pm</div>
                                                    <div className="text-tiny" style={{color: "#cec6c3"}}>Thu: 9:00 am - 9:00 pm</div>
                                                    <div className="text-tiny" style={{color: "#cec6c3"}}>Fri: 9:00 am - 10:00 pm</div>
                                                    <div className="text-tiny" style={{color: "#cec6c3"}}>Sat: 9:00 am - 10:00 pm (11:00 pm - Latin Nights)</div>
                                                </div>
                                            </PopoverContent>
                                        </Popover>
                                    </NavbarItem>
                                </Card>
                            </NavbarMenuItem>
                        </div>
                    </NavbarMenu>
                </Navbar>

                {curPageIndex === 0 && <Home/>}
                {curPageIndex === 1 && <Services/>}

                <footer className={"footer-element"}>
                    <Divider/>

                    <div className={"footer-content"}>
                        <p className={"footer-text"}>Olney Beer and Wine© 2023. All Rights Reserved.</p>
                        <p className={"footer-text"}>Proudly Built by DevScape© LLC</p>
                    </div>
                </footer>
            </header>
        </div>
    );
}

export default App;