import React, { useEffect, useState } from "react";
import MiguelBar from '../Assets/Miguel_Behind_Counter.jpg';
import WesternUnion from '../Assets/Western_Union.jpg';
import MoneyOrder from '../Assets/Money_Order.jpg';
import CheckCashing from '../Assets/Check_Cashing.jpg';
import BillPay from '../Assets/Bill_Pay.jpg';
import MarylandLottery from '../Assets/Last_Lottery.jpg';
import Reservation from '../Assets/Reserved_V3.jpg';
import './Home.css';
import {
    Card,
    CardHeader,
    Divider,
    Image, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow,
} from "@nextui-org/react";

function Home() {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}events/getevents`;
        const apiKey = process.env.REACT_APP_X_API_KEY;

        fetch(url, {
            method: 'GET',
            headers: {
                'X-API-KEY': apiKey,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => setEvents(data))
            .catch((error) => console.error('There was a problem with the fetch operation:', error));
    }, []);

    return (
        <div className={"home"}>
            <header className="App-header">
                <div className="full-screen-image-container">
                    <img className={"miguel-image"} src={MiguelBar} alt="Miguel Bar" />
                    <div className={"image-overlay"}></div>

                    <div className="overlay-text">
                        <h1 className={"drink-text"}>DRINK</h1>
                        <h1 className={"amp-text"}>&</h1>
                        <h1 className={"talk-text"}>TALK</h1>
                    </div>
                </div>

                <div style={{padding: "1% 1%", backgroundColor: "#edebe5", width: "100%", height: "100%", textAlign: 'center'}}>
                    <h1 className={"services-title"} style={{marginTop: "5%"}}>PERFECT TASTES AND WONDERFUL AROMAS FILL EVERY CORNER OF THIS OLD-WORLD STYLE PUB.</h1>
                    <Divider className="custom-divider" />
                    <p className={"services-context"}>We are a community store in Olney providing our patrons with a comfortable environment where you are invited to relax, have some drinks, and gamble at your leisure! Every day we make it our mission to exceed the expectations of our patrons with our fast, friendly service and clean environment. Our extensive tap selection is sure to please even your most discerning palates.</p>

                    <div className="gap-3 grid grid-cols-1 px-8" style={{
                        maxWidth: '90%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '5%',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                        justifyContent: 'center'}}>

                        <Card className="h-[400px]">
                            <CardHeader className="absolute z-10 flex-col !items-start bg-black/10 top-unit-0 border-t-0 border-default-200 light:border-default-600 backdrop-blur">
                                <p className="text-tiny text-white/60 uppercase font-bold">Western Union</p>
                                <h4 className="text-white font-medium text-large">We offer it!</h4>
                            </CardHeader>
                            <Image
                                removeWrapper
                                alt="Card background"
                                className="z-0 w-full h-full object-cover"
                                src={WesternUnion}
                                isZoomed
                            />
                        </Card>

                        <Card className="h-[400px]">
                            <CardHeader className="absolute z-10 flex-col !items-start bg-black/50 top-unit-0 border-t-0 border-default-200 light:border-default-600 backdrop-blur">
                                <p className="text-tiny text-white/60 uppercase font-bold">Money Order</p>
                                <h4 className="text-white font-medium text-large">Yeah we got that.</h4>
                            </CardHeader>
                            <Image
                                removeWrapper
                                alt="Card background"
                                className="z-0 w-full h-full object-cover"
                                src={MoneyOrder}
                                isZoomed
                            />
                        </Card>

                        <Card className="h-[400px]">
                            <CardHeader className="absolute z-10 flex-col !items-start bg-black/50 top-unit-0 border-t-0 border-default-200 light:border-default-600 backdrop-blur">
                                <p className="text-tiny text-white/60 uppercase font-bold">Check Cashing</p>
                                <h4 className="text-white font-medium text-large">Yep.. This too.</h4>
                            </CardHeader>
                            <Image
                                removeWrapper
                                alt="Card background"
                                className="z-0 w-full h-full object-cover"
                                src={CheckCashing}
                                isZoomed
                            />
                        </Card>

                        <Card className="h-[400px]">
                            <CardHeader className="absolute z-10 flex-col !items-start bg-black/40 top-unit-0 border-t-0 border-default-200 light:border-default-600 backdrop-blur">
                                <p className="text-tiny text-white/60 uppercase font-bold">Bill Pay</p>
                                <h4 className="text-white font-medium text-large">Honestly.. Ew.</h4>
                            </CardHeader>
                            <Image
                                removeWrapper
                                alt="Card background"
                                className="z-0 w-full h-full object-cover"
                                src={BillPay}
                                isZoomed
                            />
                        </Card>

                        <Card className="h-[400px]">
                            <CardHeader className="absolute z-10 flex-col !items-start bg-black/50 top-unit-0 border-t-0 border-default-200 light:border-default-600 backdrop-blur">
                                <p className="text-tiny text-white/60 uppercase font-bold">Lottery</p>
                                <h4 className="text-white font-medium text-large">Now we're talking!</h4>
                            </CardHeader>
                            <Image
                                removeWrapper
                                alt="Card background"
                                className="z-0 w-full h-full"
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: 'right'
                                }}
                                src={MarylandLottery}
                                isZoomed
                            />
                        </Card>

                        <Card className="h-[400px]">
                            <CardHeader className="absolute z-10 flex-col !items-start bg-black/50 top-unit-0 border-t-0 border-default-200 light:border-default-600 backdrop-blur">
                                <p className="text-tiny text-white/60 uppercase font-bold">Store Reservations</p>
                                <h4 className="text-white font-medium text-large">Party? Where's my invite?</h4>
                            </CardHeader>
                            <Image
                                removeWrapper
                                alt="Card background"
                                className="z-0 w-full h-full object-cover"
                                src={Reservation}
                                isZoomed
                            />
                        </Card>
                    </div>
                </div>

                <div className={"events-container"}>
                    <h1 className={"services-title"} style={{color: 'white', textAlign: 'center'}}>LIVE MUSIC & MORE</h1>
                    <Divider className="custom-divider-dark"/>
                    <p className={"services-context"} style={{color: '#b9b9b9', textAlign: 'center'}}>At Olney Beer, Wine, and Bar, we celebrate the joy of fine beverages, food, music, and dancing. Our curated events offer something for everyone, including vibrant live music performances to get you dancing the night away! Whether you're a connoisseur of fine wines, a craft beer enthusiast, or simply looking to enjoy a night out filled with rhythm and flavor, our store is the place to be. Explore our upcoming events and join us for an unforgettable experience of taste, sound, and movement.</p>

                    <Table color="warning" selectionMode='single' defaultSelectedKeys={["1"]} aria-label="Example empty table">
                        <TableHeader>
                            <TableColumn>GROUP</TableColumn>
                            <TableColumn>DESCRIPTION</TableColumn>
                            <TableColumn>DATE</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {events.map((event, index) => {
                                const dateObject = new Date(event.date);
                                const formattedDate = dateObject.toLocaleDateString();

                                return (
                                    <TableRow key={index}>
                                        <TableCell>{event.name}</TableCell>
                                        <TableCell>{event.description}</TableCell>
                                        <TableCell>{formattedDate}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </header>
        </div>
    );
}

export default Home;