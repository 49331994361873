import React, { useEffect, useState, useMemo, useCallback } from "react";
import './Services.css';
import {
    Pagination,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow
} from "@nextui-org/react";

function Services() {
    var [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const rowsPerPage = 10;

    useEffect(() => {
        async function loadData(offset = 0) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}clover/getallitems?offset=${offset}`, {
                    headers: {
                        'X-API-KEY': process.env.REACT_APP_X_API_KEY,
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                setItems((prevItems) => [...prevItems, ...data.elements]);
                setIsLoading(false);

                if (data.elements.length > 0) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    await loadData(offset + 1000);
                }
            } catch (error) {
                console.error(`Failed to fetch: ${error}`);
            }
        }

        loadData().catch(error => {
            console.error("Failed to load data: ", error);
        });
    }, []);

    items = items.filter(item => item['stockCount'] > 0);

    items.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    const pages = Math.ceil(items.length / rowsPerPage);

    const displayedItems = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;
        return items.slice(start, end);
    }, [page, items]);

    const onPaginationChange = useCallback(
        (page) => {
            setPage(page);
        },
        [],
    );

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });



    return (
        <div className={"services"}>
            <header className="App-header" style={{margin: '2% 10%'}}>
                <Table
                    aria-label="Example table with client async pagination"
                    bottomContent={
                        pages > 0 ? (
                            <div className="flex w-full justify-center">
                                <Pagination
                                    isCompact
                                    showControls
                                    showShadow
                                    color="primary"
                                    page={page}
                                    total={pages}
                                    onChange={onPaginationChange}
                                />
                            </div>
                        ) : null
                    }
                    classNames={{
                        table: "min-h-[400px]",
                    }}
                >
                    <TableHeader>
                        <TableColumn key="name">Name</TableColumn>
                        <TableColumn key="stockCount">Stock</TableColumn>
                        <TableColumn key="code">Code</TableColumn>
                        <TableColumn key="price">Price</TableColumn>
                    </TableHeader>

                    <TableBody
                        isLoading={isLoading && !displayedItems.length}
                        items={displayedItems}
                        loadingContent={<Spinner />}
                    >
                        {(item) => (
                            <TableRow key={item.id}>
                                {(columnKey) =>
                                    columnKey === 'price' ?
                                        <TableCell>{currencyFormatter.format(item[columnKey] / 100)}</TableCell> :
                                        <TableCell>{item[columnKey]}</TableCell>
                                }
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </header>
        </div>
    );
}

export default Services;